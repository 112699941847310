@import '../../../../scss/theme-bootstrap';

.content-block--mpp-tout {
  .content-block {
    &__content-buttons {
      &.links-width {
        &-50 .button {
          width: 50%;
        }
        &-75 .button {
          width: 75%;
        }
        &-100 .button {
          width: 100%;
        }
      }
    }
    .position--bare,
    .position-image {
      @include contentBlockImageHeight;
      .content-block__content:not(.justify-end) {
        .content-block__content-buttons {
          &.position-bottom-links {
            position: absolute;
            bottom: 0;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
          }
        }
      }
    }
    .position-text {
      @include contentBlockTextHeight;
    }
  }
}
